<template>
        <Carousel :autoplay="20000" :wrap-around="true" class="slider-for w-full h-full">
            <Slide v-for="slide in slides" :key="slide.id" v-bind:id="slide.id" :class="'w-full h-screen relative slide-holder_'+slide.id">
                <div class="logo absolute left-4 w-40 h-40 top-12 p-8 rounded-full border-2">
                    <svg class="absolute -top-1 -left-1 transform -rotate-90" height="164" width="164">
                        <circle class="circle" cx="82" cy="82" r="79" stroke="#da723c" stroke-width="4" fill-opacity="0" />
                    </svg>
                    <img class="" :src="logo()" alt="">
                </div>
                <div class="qrcode absolute left-4 w-40 bottom-16 p-2">
                    <p class="font-body text-subS text-halfColonialWhite pb-4">Înregistrează-te!</p>
                    <img class="rounded" :src="qrCode()" alt="">
                </div>
                <div class="w-9/12 block text-left absolute top-0">
                    <div>
                        <div class="relative h-1 w-8/12 inline-block left mt-32">
                            <span class="absolute left-0 top-0 h-full bg-halfColonialWhite opacity-30 w-full"></span>
                            <span :class=" 'progressBar absolute left-0 top-0 h-full bg-redDamask opacity-1'" :style="'width:' + slide.id*20 + '%'"></span>
                        </div>
                        <p class="text-halfColonialWhite font-heading text-h3 slide w-4/12 inline-block left mt-32 absolute -right-6 -top-6">0{{slide.id}}/05</p>
                        <div class="w-8/12 mt-16">
                            <h1 v-if="slide.id == '1'" v-html="slide.title" class="font-heading text-10xl text-redDamask">
                                
                            </h1>
                            <h1 v-else v-html="slide.title" class="font-heading text-7xl text-redDamask">
                                
                            </h1>
                            <div v-html="slide.text" class="font-body text-subM text-halfColonialWhite w-8/12 my-10">

                            </div>

                        </div>
                        
                        <div v-if="slide.listItem1 !='' " class="font-body text-subM text-halfColonialWhite w-8/12">
                            <ol class="list-decimal">
                                <li class="mb-6" v-html="slide.listItem1">
                                </li>
                                <li class="mb-6" v-html="slide.listItem2">
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div :class="'absolute slide_image slide_image_'+slide.id">
                    <img :class="'slide'+ slide.id" :src="slideImage(slide.id)" alt="">
                </div>
                
            </Slide>
        </Carousel>
</template>

<script>

import { defineComponent } from 'vue';
import { Carousel, Slide } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent({
    name: 'WelcomeScreen',
    components: {
        Carousel,
        Slide,
    },
    data() {
    return {
      slides: [
        { 
            id: '1',
            title: 'Bine ați venit!',
            text: 'Pentru a afla cum puteți explora portofoliul digital al muzeului, vă rugăm să parcurgeți pașii prezentați.',
            listItem1: '',
            listItem2: '',
        },
        { 
            id: '2',
            title: 'Pentru accesarea conținutului digital oferit de muzeu, <span class="text-halfColonialWhite">este nevoie să vă înregistrați.</span>',
            text: 'Aveți la dispoziție două metode simple:',
            listItem1: 'Scanați <b>codul QR</b> alăturat cu telefonul.',
            listItem2: 'Folosiți <b>tablet-ul</b> de lângă ecran și urmăriți instrucțiunile.'
        },
        { 
            id: '3',
            title: 'În expoziții veți găsi televizoare interactive și tablete.',
            text: 'Descoperiți materialele digitale, folosind aceste dispozitive, însă, multe informații suplimentare puteți găsi și cu ajutorul telefonului Dvs.',
            listItem1: '',
            listItem2: '',
        },
        { 
            id: '4',
            title: 'După înregistrare, scanați codurile QR afișate în expoziții, cu ajutorul aplicației web deschise pe telefon.',
            text: 'Veți descoperii imagini și descrieri suplimentare, precum și materiale audio-vizuale, modelări 3D și AR. Pentru redarea materialelor multimedia ,vă rugăm să folosiți căștile proprii sau cele disponibile în sălile expoziționale.',
            listItem1: '',
            listItem2: '',
        },
        { 
            id: '5',
            title: 'Timeline – amintire digitală din muzeu!',
            text: 'Din materialele digitale explorate pe parcursul vizitei, puteți să creați o colecție proprie, pe care puteți să o salvați și ulterior să o revedeți sau să o împărtășiți cu alții. Aveți posibilitatea de a adăuga orice obiect prezentat în timeline-ul personal, cu posibilitate de editare la sfârșitul vizitei. Totul este simplu :) Succes!',
            listItem1: '',
            listItem2: '',
         }
      ]
    }
  },
  methods: {
        slideImage(id){
            return require("../assets/slider/slide"+id+".png");
        },
        logo(){
            return require("../assets/Logo-light.png");
        },
        qrCode(){
            return require("../assets/slider/qrcode.png");
        },
    },
});

</script>

<style>
    .carousel__viewport{
        height: 100vh;
    }
    .slide_image{
        right: 0;
        top: 20%;
        width: 38%;
        height: 100%;
    }
    .carousel__slide--active .slide_image{
        right: -2%;
    }
    .carousel__slide--active .slide_image_4 {
        right: 10%;
        top: 25%;
        width: 18%;
        height: 100%;
    }
    .list-decimal{
        counter-reset: my-awesome-counter;
        list-style: none;
        padding-left: 50px;
    }
    .list-decimal li {
        margin: 0 0 0.5rem 0;
        counter-increment: my-awesome-counter;
        position: relative;
    }
    .list-decimal li::before {
        content: counter(my-awesome-counter);
        color: #FFFFFF;
        position: absolute;
        --size: 32px;
        padding: 3px;
        left: calc(-1 * var(--size) - 15px);
        line-height: var(--size);
        width: var(--size);
        height: var(--size);
        top: 0;
        background: #DA723C;
        border-radius: 50%;
        text-align: center;
    }
    .logo{
        border: 2px solid rgba(253, 241, 214, 0.3);
    }
    .carousel__slide--active .circle {
        stroke-dasharray: 1000;
        stroke-dashoffset: 1000;
        animation: stroke 58s ease-out forwards;
    }
    @keyframes stroke {
        to {
            stroke-dashoffset: 0;
        }
    }
    /* .carousel__slide--active.slide-holder_1 .progressBar {
        width: 0%;
        animation: progress1 20s linear forwards;
    }
    .carousel__slide--active.slide-holder_2 .progressBar {
        width: 20%;
        animation: progress2 20s linear forwards;
    }
    .carousel__slide--active.slide-holder_3 .progressBar {
        width: 40%;
        animation: progress3 20s linear forwards;
    }
    .carousel__slide--active.slide-holder_4 .progressBar {
        width: 60%;
        animation: progress4 20s linear forwards;
    }
    .carousel__slide--active.slide-holder_5 .progressBar {
        width: 80%;
        animation: progress5 20s linear forwards;
    } */
    .carousel__slide--active .slide_image {
        transform: scale(0.9);
        animation: scaleImage 20s linear forwards;
    }

    /* @keyframes progress1 {
        to {
            width: 20%;
        }
    }
    @keyframes progress2 {
        to {
            width: 40%;
        }
    }
    @keyframes progress3 {
        to {
            width: 60%;
        }
    }
    @keyframes progress4 {
        to {
            width: 80%;
        }
    }
    @keyframes progress5 {
        to {
            width: 100%;
        }
    } */
    @keyframes scaleImage {
        to {
            transform: scale(1);
        }
    }

</style>